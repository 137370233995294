import * as Icons from 'lucide-react'
import colors from 'tailwindcss/colors'

import type { Roles } from '@/types'

export const MAX_IMAGE_SIZE = 5242880 // 5 MB
export const ALLOWED_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/webp', 'image/jpg'] as const
export const BOARD_STATUS = { BELUM_DIMULAI: 'Belum Dimulai', DIKERJAKAN: 'Dikerjakan', SELESAI: 'Selesai' } as const
export const FORM_GANGGUAN_STATUS = { DIAJUKAN: 'Diajukan', DITERIMA: 'Diterima', SELESAI: 'Selesai', DITOLAK: 'Ditolak' } as const
export const FORM_GANGGUAN_PRIORITY = { MENDESAK: 'Mendesak', SEDANG: 'Sedang', BIASA: 'Biasa' } as const
export const ROLES = { MANAGER: 'MANAGER', SUPERVISOR: 'SUPERVISOR', ENGINEER: 'ENGINEER', USER: 'USER' } as const
export const ACCOUNT_STATUS = { ACTIVE: 'ACTIVE', INACTIVE: 'INACTIVE' } as const
export const TRASH_STATUS = { DRAFT: 'DRAFT', DELETED: 'DELETED' } as const
export const TRASH_TYPE = { LAPORAN: 'LAPORAN' } as const
export const MAX_FILE_SIZE = 10 * 1024 * 1024 // 10 MB
export const FILE_ENUM = { IMAGE: 'IMAGE', DOCUMENT: 'DOCUMENT', OTHER: 'OTHER' } as const
export const UPLOAD_FILE_PURPOSE = {
  FORM_GANGGUAN: 'FORM_GANGGUAN',
  FORM_GANGGUAN_BUKTI: 'FORM_GANGGUAN_BUKTI',
  BERITA_ACARA_BUKTI_PEKERJAAN: 'BERITA_ACARA_BUKTI_PEKERJAAN',
  BERITA_ACARA_LAMPIRAN: 'BERITA_ACARA_LAMPIRAN',
  BERITA_ACARA_ESIGN: 'BERITA_ACARA_ESIGN'
} as const

export const FORM_GANGGUAN_PRIORITY_COLORS: Record<keyof typeof FORM_GANGGUAN_PRIORITY, string> = {
  BIASA: colors.green[600],
  SEDANG: colors.yellow[500],
  MENDESAK: '#EF4444' // text-destructive
}

export const FORM_GANGGUAN_STATUS_COLORS: Record<keyof typeof FORM_GANGGUAN_STATUS, string> = {
  DIAJUKAN: '#0099F6', // text-app-blue
  DITERIMA: colors.green[600],
  SELESAI: colors.green[600],
  DITOLAK: '#ef4444' // text-destructive
}

export const adminMenu: Menu[] = [
  { name: 'Dashboard', href: '/', icon: Icons.Home, role: ['MANAGER', 'SUPERVISOR', 'ENGINEER'] },
  { name: 'Board', href: '/board', icon: Icons.LayoutDashboard, role: ['MANAGER', 'SUPERVISOR', 'ENGINEER'] },
  { name: 'Kalender', href: '/kalender', icon: Icons.Calendar, role: ['MANAGER', 'SUPERVISOR', 'ENGINEER'] },
  {
    name: 'Laporan',
    href: '/laporan',
    icon: Icons.FileText,
    role: ['MANAGER', 'SUPERVISOR']
  },
  {
    name: 'Arsip Laporan',
    href: '/arsip-laporan',
    icon: Icons.FileCheck,
    role: ['MANAGER', 'SUPERVISOR', 'ENGINEER']
  },
  {
    name: 'Data Master',
    icon: Icons.Database,
    role: ['MANAGER', 'SUPERVISOR'],
    subMenu: [
      {
        name: 'Data Unit',
        href: '/data-master/unit',
        icon: Icons.Boxes
      },
      {
        name: 'Data Gardu',
        href: '/data-master/gardu',
        icon: Icons.Factory
      },
      {
        name: 'Data Bay',
        href: '/data-master/bay',
        icon: Icons.UtilityPole
      },
      {
        name: 'Jenis Gangguan',
        href: '/data-master/jenis-gangguan',
        icon: Icons.ServerCrash
      },
      {
        name: 'Sub Jenis Gangguan',
        href: '/data-master/sub-jenis-gangguan',
        icon: Icons.ServerCrash
      }
    ]
  },
  {
    name: 'Account Management',
    icon: Icons.Users,
    role: ['MANAGER', 'SUPERVISOR'],
    subMenu: [
      {
        name: 'Unverified User',
        href: '/account-management/unverified',
        icon: Icons.UserX
      },
      {
        name: 'Manager',
        href: '/account-management/manager',
        icon: Icons.HardHat
      },
      {
        name: 'Supervisor',
        href: '/account-management/supervisor',
        icon: Icons.HardHat
      },
      {
        name: 'Engineer',
        href: '/account-management/engineer',
        icon: Icons.Cog
      },
      {
        name: 'User',
        href: '/account-management/user',
        icon: Icons.User
      }
    ]
  },
  {
    name: 'Mean Time to Repair (MTTR)',
    icon: Icons.TrendingUpDown,
    role: ['MANAGER', 'SUPERVISOR'],
    subMenu: [
      {
        name: 'MTTR Gardu',
        href: '/mttr/gardu',
        icon: Icons.Factory
      },
      {
        name: 'MTTR Bay',
        href: '/mttr/bay',
        icon: Icons.UtilityPole
      }
    ]
  },
  { name: 'Sampah', href: '/trash', icon: Icons.Trash2, role: ['MANAGER', 'SUPERVISOR'] },
  { name: 'Pengaturan', href: '/settings', icon: Icons.ServerCog, role: ['MANAGER', 'SUPERVISOR', 'ENGINEER'] }
]

export interface Menu {
  name: string
  href?: string
  icon: Icons.LucideIcon
  role: Omit<Roles, 'USER'>[]
  subMenu?: Omit<Omit<Menu, 'subMenu'>, 'role'>[] | null
}
