import dayjs from 'dayjs'
import 'dayjs/locale/id'
dayjs.locale('id')
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)
import { toast } from 'sonner'
import { useQuery } from '@tanstack/react-query'
import { Loader2, AlertCircle } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'
import { ErrorBoundary } from 'react-error-boundary'
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { Suspense, useEffect, useSyncExternalStore, useCallback, lazy, useState } from 'react'

import * as Page from '@/pages'
import { exclude, ROLES } from '@/lib'
import { accountStore, layoutStore } from '@/stores'
import { version as appVersion } from '@/../package.json'

import { Card } from './components/ui/card'
import { Button } from './components/ui/button'
import { Toaster } from '@/components/ui/sonner'
import { ThemeProvider } from './pages/admin/_components'
import { TooltipProvider } from '@/components/ui/tooltip'
import { ResponsiveIndicator } from '@/components/responsive-indicator'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'

import type { Roles } from './types'

// This is a hack to make React Query Devtools work in development build
const ReactQueryDevtoolsDevelopment = lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then(d => ({
    default: d.ReactQueryDevtools
  }))
)

declare global {
  interface Window {
    toggleDevtools: () => void
  }
}

export default function App() {
  const [showReactQueryDevtools, setShowReactQueryDevtools] = useState(import.meta.env.VITE_ENVIRONMENT === 'development')

  const setAppVersion = layoutStore(state => state.setAppVersion)
  const account = accountStore(useShallow(state => state.account))
  const isAuthenticated = Boolean(account)
  const role = account?.role

  useQuery({
    queryKey: ['app-version'],
    queryFn: async () => {
      const response = await fetch('/meta.json', { cache: 'no-cache' })
      if (response.status === 304) return null
      const { version } = (await response.json()) as { version: string }
      if (version && version !== appVersion) setAppVersion(version)
      return version
    },
    initialData: appVersion,
    refetchInterval: 86400000, // 1 day
    refetchIntervalInBackground: true
  })

  useSyncExternalStore(
    useCallback((callback: () => void) => {
      const handleOnline = () => {
        toast.success('Koneksi internet telah pulih!')
        callback()
      }
      const handleOffline = () => {
        toast.error('Koneksi internet terputus!')
        callback()
      }

      window.addEventListener('online', handleOnline)
      window.addEventListener('offline', handleOffline)

      return () => {
        window.removeEventListener('online', handleOnline)
        window.removeEventListener('offline', handleOffline)
      }
    }, []),
    () => navigator.onLine
  )

  useEffect(() => {
    window.toggleDevtools = () => setShowReactQueryDevtools(prev => !prev)
  }, [])

  return (
    <ThemeProvider defaultTheme='light' storageKey='ui-theme'>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <TooltipProvider>
          <BrowserRouter>
            <SaveIntendedPath isAuthenticated={isAuthenticated} />
            <Suspense fallback={<Fallback />}>
              <Routes>
                <Route path='esign' element={<Page.EsignPage />} />
                {!isAuthenticated ? (
                  <Route path='/' element={<Page.AuthLayout />}>
                    <Route index element={<Page.AuthSignInPage />} />
                    <Route path='signin' element={<Page.AuthSignInPage />} />
                    <Route path='signup' element={<Page.AuthSignUpPage />} />
                    <Route path='*' element={<Navigate to='/' replace />} />
                  </Route>
                ) : (
                  <>
                    {role === 'USER' && (
                      <Route path='/' element={<Page.UserLayout />}>
                        <Route index element={<Page.UserHomePage />} />
                        <Route path='buat-laporan' element={<Page.UserBuatLaporanPage />} />
                        <Route path='status-laporan'>
                          <Route index element={<Page.UserStatusLaporanPage />} />
                          <Route path=':id' element={<Page.UserStatusLaporanDetailPage />} />
                        </Route>
                        <Route path='profile' element={<Page.AdminProfileDetail />} />
                        <Route path='*' element={<Page.User404Page />} />
                      </Route>
                    )}

                    {Object.values(exclude(ROLES, ['USER'])).includes(role as Exclude<Roles, 'USER'>) && (
                      <Route path='/' element={<Page.AdminLayout />}>
                        <Route index element={<Page.AdminHomePage />} />
                        <Route path='board'>
                          <Route index element={<Page.AdminBoardPage />} />
                          <Route path=':id' element={<Page.AdminBoardDetailPage />} />
                          <Route path=':id/buat-berita-acara' element={<Page.AdminBoardDetailCreateReport />} />
                        </Route>
                        <Route path='kalender' element={<Page.AdminCalendarPage />} />
                        <Route path='laporan'>
                          <Route index element={<Page.AdminLaporanPage />} />
                          <Route path=':id' element={<Page.AdminLaporanDetailPage />} />
                        </Route>
                        <Route path='arsip-laporan'>
                          <Route index element={<Page.AdminLaporanArchivedPage />} />
                          <Route path=':id' element={<Page.AdminLaporanArchivedDetailPage />} />
                        </Route>
                        <Route path='data-master'>
                          <Route index element={<Navigate to='/data-master/unit' replace />} />
                          <Route path='unit' element={<Page.AdminDMUnit />} />
                          <Route path='gardu' element={<Page.AdminDMGardu />} />
                          <Route path='bay' element={<Page.AdminDMBay />} />
                          <Route path='jenis-gangguan' element={<Page.AdminDMJenisGangguan />} />
                          <Route path='sub-jenis-gangguan' element={<Page.AdminDMSubJenisGangguan />} />
                        </Route>
                        <Route path='account-management'>
                          <Route index element={<Navigate to='/account-management/unverified' replace />} />
                          <Route path=':type' element={<Page.AdminAMList />} />
                          <Route path=':type/:id' element={<Page.AdminAMDetail />} />
                        </Route>
                        <Route path='mttr'>
                          <Route index element={<Navigate to='/mttr/gardu' replace />} />
                          <Route path='gardu' element={<Page.AdminMTTRGardu />} />
                          <Route path='bay' element={<Page.AdminMTTRBay />} />
                        </Route>
                        <Route path='trash' element={<Page.AdminTrashList />} />
                        <Route path='settings' element={<Page.AdminSettings />} />
                        <Route path='profile' element={<Page.AdminProfileDetail />} />
                        <Route path='*' element={<Page.Admin404Page />} />
                      </Route>
                    )}
                  </>
                )}
              </Routes>
            </Suspense>
          </BrowserRouter>
        </TooltipProvider>

        <Toaster />
        <ResponsiveIndicator />
        {showReactQueryDevtools && (
          <Suspense fallback={null}>
            <ReactQueryDevtoolsDevelopment initialIsOpen={true} />
          </Suspense>
        )}
      </ErrorBoundary>
    </ThemeProvider>
  )
}

const SaveIntendedPath = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
  const location = useLocation()

  useEffect(() => {
    if (!isAuthenticated && location.pathname !== '/' && location.pathname !== '/signup' && !location.pathname.startsWith('/signin')) {
      localStorage.setItem('intendedPath', location.pathname)
    }
    if (!isAuthenticated && location.pathname.startsWith('/signin') && location.pathname.startsWith('/signup') && location.pathname !== '/') {
      localStorage.removeItem('intendedPath')
    }
  }, [isAuthenticated, location.pathname])

  return null
}

const Fallback = () => {
  return (
    <main className='w-dvh grid h-dvh items-center justify-center'>
      <Loader2 size='64' className='animate-spin text-primary' />
    </main>
  )
}

const ErrorFallback = ({ error, resetErrorBoundary }: { error: Error; resetErrorBoundary: () => void }) => {
  const developers = [
    {
      name: 'WS',
      image:
        'https://media.licdn.com/dms/image/v2/D5603AQFc0EebXqZFMA/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1672277551315?e=1730332800&v=beta&t=gOoEbyRDF3SzzPqfKw0wCpgUzjGcbHN2Ie8WuXLtELY',
      contact: '+6287885002327'
    },
    {
      name: 'APS',
      image:
        'https://media.licdn.com/dms/image/v2/C5603AQG-ML3MuxAXkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1648200971089?e=1730332800&v=beta&t=Dn8SYuKw8rOmZdIBRcICTzpOaXyznSjLJ3xCTbbSXVE',
      contact: '+6281226696696'
    },
    {
      name: 'SM',
      image:
        'https://media.licdn.com/dms/image/v2/D5603AQFNpKgFSLGxGw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676793247405?e=1730332800&v=beta&t=zu7J2Uq_4Yqnk5liTIWlmTtDGLnNLHAAGU8OVRJVdGw',
      contact: '+6281264668115'
    }
  ]

  return (
    <main role='alert' className='flex items-center justify-center min-h-dvh bg-muted p-4'>
      <Card className='w-full max-w-lg p-6 space-y-6'>
        <div className='flex items-center space-x-2 text-destructive'>
          <AlertCircle className='w-6 h-6' />
          <h2 className='text-xl font-semibold'>Oops! Terjadi kesalahan</h2>
        </div>
        <div className='p-3 bg-muted rounded-md overflow-auto'>
          <code className='text-sm'>
            <pre>{error.message}</pre>
          </code>
        </div>
        <Button onClick={resetErrorBoundary} variant='destructive' className='w-full'>
          Coba lagi
        </Button>
        <div className='space-y-4'>
          <p className='text-sm text-center'>Atau... ayo cari siapa yang bisa disalahkan dari tim developer untuk masalah ini 😂</p>
          <div className='grid grid-cols-3 gap-4'>
            {developers.map(dev => (
              <a key={dev.name} href={`https://wa.me/${dev.contact}`} target='_blank' rel='noopener noreferrer'>
                <Avatar className='w-full h-auto aspect-square border-2 border-destructive hover:border-primary-dark transition-all hover:animate-spin'>
                  <AvatarImage src={dev.image} />
                  <AvatarFallback>{dev.name}</AvatarFallback>
                </Avatar>
              </a>
            ))}
          </div>
        </div>
      </Card>
    </main>
  )
}
