import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

import { adminMenu } from '@/lib'
import { version } from '@/../package.json'

/**
 * Interface representing the state of the user store.
 */
interface LayoutState {
  /**
   * The current theme of the application.
   */
  appVersion: string

  /**
   * A function to update the user details in the store.
   * @param user - The new user details object, or null to log out the user.
   */
  setAppVersion: (appVersion: string) => void

  /**
   * Whether the sidebar is collapsed.
   */
  isCollapsed: boolean

  /**
   * A function to update the sidebar collapsed state in the store.
   * @param isCollapsed - Whether the sidebar is collapsed.
   */
  setIsCollapsed: (isCollapsed: boolean) => void

  /**
   * The sizes of the layout panes.
   * The first element is the size of the sidebar, and the second element is the size of the main content.
   */
  layoutSizes: number[]

  /**
   * A function to update the layout sizes in the store.
   * @param layoutSizes - The new layout sizes.
   */
  setLayoutSizes: (layoutSizes: number[]) => void

  /**
   * Whether the accordion is open.
   * The key is the ID of the accordion, and the value is whether it is open.
   */
  accordionOpen: string[]

  /**
   * A function to update the accordion open state in the store.
   *
   */
  setAccordionOpen: (accordionOpen: string[]) => void

  /**
   * The current theme of the application.
   * Can be 'dark', 'light', or 'system'.
   */
  theme: 'dark' | 'light' | 'system'

  /**
   * A function to update the theme in the store.
   * @param theme - The new theme.
   */
  setTheme: (theme: 'dark' | 'light' | 'system') => void
}

/**
 * The user store created using Zustand, with state persistence and secure storage.
 */
export const layoutStore = create<LayoutState>()(
  persist(
    set => ({
      appVersion: version,
      setAppVersion: (appVersion: string) => set({ appVersion }),

      isCollapsed: false,
      setIsCollapsed: (isCollapsed: boolean) => set({ isCollapsed }),

      layoutSizes: [15, 85],
      setLayoutSizes: (layoutSizes: number[]) => set({ layoutSizes }),

      accordionOpen: adminMenu.filter(item => item.subMenu).map(item => item.name),
      setAccordionOpen: (accordionOpen: string[]) => set({ accordionOpen }),

      theme: 'system',
      setTheme: (theme: 'dark' | 'light' | 'system') => set({ theme })
    }),
    {
      name: 'layout-state',
      storage: createJSONStorage(() => localStorage)
    }
  )
)
