import { Zap } from 'lucide-react'
import { NavLink } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'

import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'

import { cn } from '@/lib'
import { accountStore, layoutStore } from '@/stores'
import { adminMenu, Menu as TMenu } from '@/lib/constants'

export const Sidebar = () => {
  const [account] = accountStore(useShallow(state => [state.account]))
  const [appVersion, isCollapsed, accordionOpen, setAccordionOpen] = layoutStore(useShallow(state => [state.appVersion, state.isCollapsed, state.accordionOpen, state.setAccordionOpen]))

  return (
    <>
      <header className={cn('mb-5 flex h-16 shrink-0 items-center gap-x-2.5 transition-all', isCollapsed ? 'justify-center' : 'justify-start')}>
        <img src='/PLN.png' className='w-10 dark:hidden shrink-0' alt='PLN Logo' />
        <Zap size={isCollapsed ? 40 : 52} className='hidden dark:block shrink-0' fill='white' />
        {!isCollapsed && (
          <div className='truncate'>
            <p className='text-xl font-semibold truncate'>
              Lapormas
              {import.meta.env.VITE_ENVIRONMENT === 'development' && <span className='text-xs font-semibold text-muted-foreground ml-1.5'>DEV</span>}
            </p>
            <p className='font-medium truncate text-muted-foreground'>{account!.up2b.name}</p>
          </div>
        )}
      </header>
      <ul className='overflow-y-auto overflow-x-hidden scrollbar-hide flex-1'>
        <Accordion type='multiple' value={accordionOpen} onValueChange={e => setAccordionOpen(e)}>
          {adminMenu
            .filter(item => item.role.includes(account!.role))
            .map((menu, i) =>
              menu.subMenu ? (
                <li key={i}>
                  <AccordionItem value={menu.name} className={cn(isCollapsed ? 'w-10 mx-auto' : '', 'transition-all')}>
                    <TooltipWrapper enable={isCollapsed} title={menu.name}>
                      <AccordionTrigger
                        withChevron={!isCollapsed}
                        className={cn(
                          'rounded p-2 text-sm font-semibold leading-6 text-muted-foreground transition-all [&.active]:bg-muted [&.active]:text-app-blue truncate',
                          isCollapsed ? 'justify-center' : ''
                        )}
                      >
                        <div className='flex items-center gap-x-3 text-start truncate'>
                          <menu.icon size={24} className='shrink-0' />
                          {!isCollapsed && <span className='text-sm truncate'>{menu.name}</span>}
                        </div>
                      </AccordionTrigger>
                    </TooltipWrapper>
                    <AccordionContent className={cn('relative transition-all', isCollapsed ? 'ml-0' : 'ml-5')}>
                      {!isCollapsed && <div className='absolute left-0 top-0 h-full w-px bg-accent' />}
                      <ul className={cn('transition-all', isCollapsed ? 'pl-0' : 'pl-2')}>
                        {menu.subMenu.map((subMenu, j) => (
                          <Menu key={j} data={subMenu} isCollapsed={isCollapsed} />
                        ))}
                      </ul>
                    </AccordionContent>
                  </AccordionItem>
                </li>
              ) : (
                <li key={i}>
                  <Menu data={menu} isCollapsed={isCollapsed} />
                </li>
              )
            )}
        </Accordion>
      </ul>
      <p className={cn('text-xs text-muted-foreground text-center mt-auto my-2 transition-opacity', isCollapsed ? 'opacity-0' : 'opacity-100')}>
        App Version: <span className='font-semibold'>{appVersion}</span>
      </p>
    </>
  )
}

interface MenuProps {
  data: TMenu | Omit<Omit<TMenu, 'subMenu'>, 'role'>
  isCollapsed: boolean
}

const Menu = ({ data, isCollapsed }: MenuProps) => {
  return (
    <TooltipWrapper enable={isCollapsed} title={data.name}>
      <NavLink
        to={data.href!}
        className={cn(
          'flex items-center gap-x-3 rounded p-2 text-sm font-semibold leading-6 text-muted-foreground transition-all [&.active]:bg-muted [&.active]:text-app-dark-blue dark:[&.active]:text-primary truncate',
          isCollapsed ? 'justify-center size-10 mx-auto' : 'justify-start'
        )}
      >
        <data.icon size={24} className='shrink-0' />
        {!isCollapsed && <span className='text-sm truncate'>{data.name}</span>}
      </NavLink>
    </TooltipWrapper>
  )
}

interface TooltipWrapperProps {
  children: React.ReactNode
  title: string
  enable: boolean
}

const TooltipWrapper = ({ children, enable, title }: TooltipWrapperProps) => {
  if (enable) {
    return (
      <Tooltip delayDuration={0}>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        <TooltipContent side='right'>
          <p>{title}</p>
        </TooltipContent>
      </Tooltip>
    )
  }
  return children
}
