import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

import type { Account } from '@/types'

/**
 * Interface representing the state of the user store.
 */
interface AccountState {
  /**
   * The user details object, or null if no user is logged in.
   */
  account: Account | null

  /**
   * A function to update the user details in the store.
   * @param user - The new user details object, or null to log out the user.
   */
  setAccount: (account: Account | null) => void

  /**
   * A boolean representing whether the user is authenticated.
   * This is a derived state from the `account` state.
   * It is true if the `account` state is not null.
   */
  isAuthenticated: boolean
}

/**
 * The user store created using Zustand, with state persistence and secure storage.
 */
export const accountStore = create<AccountState>()(
  persist(
    set => ({
      account: null,
      setAccount: (account: Account | null) => set({ account }),
      get isAuthenticated() {
        return Boolean(this.account)
      }
    }),
    {
      name: 'account-state',
      storage: createJSONStorage(() => localStorage)
    }
  )
)
