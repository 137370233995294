import React from 'react'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { HelmetProvider } from 'react-helmet-async'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { errorParse } from './lib/error-parse.ts'
import App from './app.tsx'
import './index.css'
import { toast } from 'sonner'

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: error => {
        const { title, description } = errorParse(error)
        toast.error(title, { description })
      }
    }
  }
})

declare global {
  interface Window {
    __REACT_DEVTOOLS_GLOBAL_HOOK__: { [key: string]: unknown }
  }
}
;(() => {
  if (import.meta.env.VITE_ENVIRONMENT !== 'production') return

  if (typeof window === 'undefined' || !window.document) return

  const devTools = window.__REACT_DEVTOOLS_GLOBAL_HOOK__

  if (typeof devTools !== 'object' || devTools === null) return

  Object.keys(devTools).forEach(prop => {
    if (prop === 'renderers') {
      devTools[prop] = new Map()
    } else if (typeof devTools[prop] === 'function') {
      devTools[prop] = Function.prototype
    } else {
      devTools[prop] = null
    }
  })
})()

Sentry.init({
  enabled: true,
  environment: import.meta.env.VITE_ENVIRONMENT,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/api-dev-v2\.lapor-mas\.id\/.*/, /^https:\/\/lapor-mas\.id\/.*/, /^https:\/\/lapormas\.wiscaksono\.com\/.*/],
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </HelmetProvider>
  </React.StrictMode>
)
