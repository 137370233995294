import { z } from 'zod'
import dayjs from 'dayjs'
import { Fragment } from 'react'
import { LogOut, User, Bell, ArrowLeft } from 'lucide-react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useLocation, useNavigate, Link } from 'react-router-dom'

import { Button } from '@/components/ui/button'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from '@/components/ui/breadcrumb'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'

import { API } from '@/lib'
import { accountStore } from '@/stores'
import { CommandMenu } from './command-menu'
import { SidebarMobile } from './sidebar-mobile'

export const Header = () => {
  return (
    <header className='sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b bg-background px-4 sm:gap-x-6 sm:px-2 lg:px-4'>
      <div className='flex flex-1 items-center gap-x-4 self-stretch lg:gap-x-6'>
        <BreadCrumb />
        <SidebarMobile />
        <div className='ml-auto flex shrink-0 items-center gap-x-4 lg:gap-x-6'>
          <DateTime />
          <div className='hidden h-6 w-px bg-muted-foreground/20 md:block' aria-hidden='true' />
          <div className='flex items-center gap-x-2'>
            <CommandMenu />
            <Notification />
            <AccountDropdown />
          </div>
        </div>
      </div>
    </header>
  )
}

interface BreadcrumbItemType {
  path: string
  label: string
}

const BreadCrumb = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const uuidSchema = z.string().uuid()
  const breadCrumbs = location.pathname.split('/').filter(crumb => crumb !== '')

  // Build an array of breadcrumb items, excluding UUIDs
  let path = ''
  const breadcrumbItems: BreadcrumbItemType[] = []

  breadCrumbs.forEach(crumb => {
    path += `/${crumb}`
    if (!uuidSchema.safeParse(crumb).success) {
      // If the crumb is not a UUID, add it to the breadcrumb items
      breadcrumbItems.push({
        path,
        label: crumb.replace(/-/g, ' ')
      })
    }
  })

  // Render the breadcrumbs
  return (
    <Breadcrumb className='hidden lg:block'>
      <BreadcrumbList>
        <button className='hidden border-r pr-2 lg:block' onClick={() => navigate(-1)} aria-label='Go back' data-umami-event='Header - Back button'>
          <ArrowLeft size={24} />
        </button>
        {breadcrumbItems.map((item, index) => (
          <Fragment key={index}>
            <BreadcrumbItem>
              {index < breadcrumbItems.length - 1 ? (
                <BreadcrumbLink asChild>
                  <Link className='capitalize' to={item.path}>
                    {item.label}
                  </Link>
                </BreadcrumbLink>
              ) : (
                <BreadcrumbPage className='capitalize'>{item.label}</BreadcrumbPage>
              )}
            </BreadcrumbItem>
            {index < breadcrumbItems.length - 1 && <BreadcrumbSeparator />}
          </Fragment>
        ))}
      </BreadcrumbList>
    </Breadcrumb>
  )
}

const Notification = () => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant='outline' size='icon' className='text-muted-foreground' aria-label='Notification' data-umami-event='Header - Notification button'>
          <Bell size={20} />
        </Button>
      </PopoverTrigger>
      <PopoverContent align='end' className='p-0 md:w-96'>
        <div className='flex items-center justify-between border-b px-4 py-2'>
          <h4 className='font-semibold'>Notifikasi</h4>
          <button className='text-sm text-muted-foreground transition-colors hover:text-foreground'>Tandai semua sudah dibaca</button>
        </div>
        <div className={`flex h-96 flex-col items-center justify-center overflow-y-auto`}>
          <p className='text-sm text-muted-foreground'>Tidak ada notifikasi</p>
        </div>
      </PopoverContent>
    </Popover>
  )
}

const AccountDropdown = () => {
  const { account, setAccount } = accountStore()

  const navigate = useNavigate()

  const { mutate } = useMutation({
    mutationKey: ['atuh/logout'],
    mutationFn: async () => {
      setAccount(null)
      await API.post('/v1/auth/logout', {})
    },
    onSuccess: async () => API.resetToken()
  })

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Avatar aria-label='Account menu' data-umami-event='Header - Account button'>
          <AvatarImage src={account!.image} alt={account!.name + ' ' + 'Profile Picture'} />
          <AvatarFallback>{account!.name[0]}</AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent align='end' className='w-40'>
        <DropdownMenuLabel className='truncate'>{account!.name}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => navigate('/profile')}>
          <User className='mr-2 h-4 w-4' />
          <span>Profile</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => mutate()}>
          <LogOut className='mr-2 h-4 w-4' />
          <span>Log out</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

const DateTime = () => {
  const { data } = useQuery({
    queryKey: ['header/date-time'],
    queryFn: () => {
      return { time: dayjs().format('HH:mm'), day: dayjs().format('dddd, DD MMM YYYY') }
    },
    refetchInterval: 60000, // 1 minute
    initialData: { time: dayjs().format('HH:mm'), day: dayjs().format('dddd, DD MMM YYYY') }
  })

  return (
    <time className='hidden text-end md:block'>
      <p className='text-sm font-bold'>{data.time}</p>
      <p className='text-xs text-gray-500'>{data.day}</p>
    </time>
  )
}
