import type { BaseResponse, RefreshToken } from '@/types'

const REFRESH_TOKEN_URL = '/v1/auth/refresh-token'
const BASE_API_URL = import.meta.env.VITE_API_BASE_URL
const USER_AGENT = import.meta.env.VITE_API_USER_AGENT

/**
 * Class representing an API client using Fetch API.
 */
export class Api {
  private baseUrl = BASE_API_URL
  private isRefreshingToken = false
  private pendingRequests: Array<(token: string) => void> = []
  private defaultHeaders: Record<string, string> = { Accept: 'application/json', 'X-Platform': USER_AGENT }

  /**
   * Get the current authentication token.
   * @returns The authentication token.
   */
  getToken(): string | null {
    return localStorage.getItem('token')
  }

  /**
   * Set authentication token.
   * @param token - The authentication token.
   */
  setToken(token: string) {
    localStorage.setItem('token', token)
  }

  /**
   * Reset authentication token.
   */
  resetToken() {
    localStorage.removeItem('token')
  }

  /**
   * Check if the user is currently logged in.
   * @returns {boolean} True if the user is logged in, false otherwise.
   */
  isLoggedIn(): boolean {
    return !!this.getToken()
  }

  /**
   * Refresh the authentication token.
   */
  async refreshToken() {
    try {
      const token = this.getToken()
      const headers: Record<string, string> = {
        'X-Platform': USER_AGENT,
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }

      const response = await fetch(`${this.baseUrl}${REFRESH_TOKEN_URL}`, {
        method: 'POST',
        headers,
        credentials: 'include'
      })
      if (!response.ok) throw new Error('Failed to refresh token')
      const data: BaseResponse<RefreshToken> = await response.json()
      this.setToken(data.data.token)
    } catch (error) {
      console.error('Failed to refresh token:', error)
      throw error
    }
  }

  /**
   * Handle token refresh logic and retry the original request.
   * @param path - The endpoint path.
   * @param options - Request options.
   * @returns A promise containing the API response.
   */
  private async handleTokenRefresh<T>(path: string, options?: RequestInit): Promise<T> {
    const promise = new Promise<T>((resolve, reject) => {
      this.pendingRequests.push((token: string) => {
        // Reattempt the original request with the new token
        if (!options) options = {}
        const headers = { ...(options.headers || {}), Authorization: `Bearer ${token}` }
        options.headers = headers

        this.request<T>(path, options).then(resolve).catch(reject)
      })
    })

    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true

      try {
        await this.refreshToken()
      } catch (refreshError) {
        this.isRefreshingToken = false
        this.resetToken()
        localStorage.clear()
        window.location.reload()
        return Promise.reject(refreshError)
      } finally {
        this.isRefreshingToken = false
        this.pendingRequests.forEach(callback => callback(this.getToken()!))
        this.pendingRequests = []
      }
    }

    return promise
  }

  /**
   * Build the request options for the API call.
   * @param method - The HTTP method ('GET', 'POST', etc.).
   * @param body - The request body.
   * @returns The request options.
   */
  private buildOptions(method: string, body?: any): RequestInit {
    const headers: Record<string, string> = { ...this.defaultHeaders }

    // Only set Content-Type for non-FormData bodies
    if (body && !(body instanceof FormData)) {
      headers['Content-Type'] = 'application/json'
    }

    return {
      method,
      credentials: 'include',
      headers,
      ...(body && { body: body instanceof FormData ? body : JSON.stringify(body) })
    }
  }

  /**
   * Make a request to the API.
   * @param path - The endpoint path.
   * @param [options] - Optional request options.
   * @returns A promise containing the API response.
   */
  async request<T>(path: string, options?: RequestInit): Promise<T> {
    // Build the request URL
    const url = `${this.baseUrl}${path}`

    // Prepare headers, starting with default headers
    const headersObj: Record<string, string> = { ...this.defaultHeaders }

    // Add 'Authorization' header if token is present
    const token = this.getToken()
    if (token) headersObj['Authorization'] = `Bearer ${token}`

    // Now merge options.headers over headersObj
    if (options?.headers) Object.assign(headersObj, options.headers)

    // Create Headers instance
    const headers = new Headers(headersObj)

    // Prepare request options
    const requestOptions: RequestInit = { credentials: 'include', ...options, headers }

    // Make the fetch call, with error handling
    try {
      const response = await fetch(url, requestOptions)

      let responseData
      const contentType = response.headers.get('content-type')

      if (contentType && contentType.includes('application/json')) {
        responseData = await response.json()
      } else {
        responseData = await response.text()
      }

      if (response.ok) {
        return responseData as T
      } else {
        // Unauthorized
        if (responseData && responseData.message === 'TokenExpired') return this.handleTokenRefresh<T>(path, options)
        if (responseData && (responseData.message === 'TokenInvalid' || responseData.message === 'AuthorizationRequired')) {
          this.resetToken()
          localStorage.clear()
          window.location.reload()
        }
        return Promise.reject(responseData)
      }
    } catch (error) {
      console.error('API request error:', error)
      return Promise.reject(error)
    }
  }

  /**
   * Make a GET request to the API.
   * @template R - The type of the response.
   * @param path - The endpoint path.
   * @returns A promise containing the API response.
   */
  get<R>(path: string): Promise<BaseResponse<R>> {
    const options = this.buildOptions('GET')
    return this.request<BaseResponse<R>>(path, options)
  }

  /**
   * Make a DELETE request to the API.
   * @template P - The type of the request body.
   * @template R - The type of the response.
   * @param path - The endpoint path.
   * @param body - The request body.
   * @returns A promise containing the API response.
   */
  delete<P, R>(path: string, body?: P): Promise<BaseResponse<R>> {
    const options = this.buildOptions('DELETE', body)
    return this.request<BaseResponse<R>>(path, options)
  }

  /**
   * Make a PUT request to the API.
   * @template P - The type of the request body.
   * @template R - The type of the response.
   * @param path - The endpoint path.
   * @param body - The request body.
   * @returns A promise containing the API response.
   */
  put<P, R>(path: string, body: P): Promise<BaseResponse<R>> {
    const options = this.buildOptions('PUT', body)
    return this.request<BaseResponse<R>>(path, options)
  }

  /**
   * Make a POST request to the API.
   * @template P - The type of the request body.
   * @template R - The type of the response.
   * @param path - The endpoint path.
   * @param body - The request body.
   * @returns A promise containing the API response.
   */
  post<P, R>(path: string, body?: P): Promise<BaseResponse<R>> {
    const options = this.buildOptions('POST', body)
    return this.request<BaseResponse<R>>(path, options)
  }
}

export const API = new Api()
