// Define types
export type ErrorParseType = {
  statusCode?: number
  status?: string
  message: string
  errors?: Array<{
    errors: Array<string>
    name: string
  }>
}

// Error list mapping
const errorList = [
  {
    key: 'PayloadInvalid',
    title: 'Payload tidak valid',
    description: 'Data yang dimasukan tidak benar!'
  },
  {
    key: 'DataNotFound',
    title: 'Data tidak ditemukan',
    description: 'Data tidak ditemukan!'
  },
  {
    key: 'Forbidden',
    title: 'Dilarang',
    description: 'Anda tidak memiliki akses untuk melakukan aksi ini!'
  },
  {
    key: 'InternalServerError',
    title: 'Internal server error',
    description: 'Terjadi kesalahan pada server!'
  },
  {
    key: 'InvalidCredentials',
    title: 'Credential tidak valid',
    description: 'Email atau password anda salah!'
  }
]

// Type guard to ensure the object has the expected structure
function isErrorParseType(obj: unknown): obj is ErrorParseType {
  return typeof obj === 'object' && obj !== null && 'message' in obj && typeof (obj as ErrorParseType).message === 'string'
}

export function errorParse(errorsData: unknown) {
  // Check if the passed data is of ErrorParseType
  if (!isErrorParseType(errorsData)) {
    throw new Error('Invalid error data format')
  }

  // Find the matching error code in the error list
  const errorCode = errorList.find(error => error.key === errorsData.message)

  const title = errorCode ? errorCode.title : errorsData.message
  let description = ''

  // Safely handle potential nested errors
  if (errorsData.errors) {
    errorsData.errors.forEach(errorItem => {
      if (Array.isArray(errorItem.errors)) {
        errorItem.errors.forEach(err => {
          description += `${err},\n`
        })
      }
    })
  }

  // Fallback to the error description if none were found
  if (description === '' && errorCode) {
    description = errorCode.description
  }

  // Prepare the response object
  const response = {
    title,
    description
  }

  return response
}
