import lazyImport from '@/hooks/lazy-import'

export const AuthSignUpPage = lazyImport(() => import('./sign-up').then(({ AuthSignUpPage }) => ({ default: AuthSignUpPage })))
export const AuthSignInPage = lazyImport(() => import('./sign-in').then(({ AuthSignInPage }) => ({ default: AuthSignInPage })))
export const Auth404Page = lazyImport(() => import('./404').then(({ Auth404Page }) => ({ default: Auth404Page })))

import { Suspense } from 'react'
import { Loader2 } from 'lucide-react'
import { Outlet } from 'react-router-dom'

export const AuthLayout = () => {
  return (
    <Suspense fallback={<Fallback />}>
      <main className='h-dvh items-center overflow-hidden lg:flex'>
        <section className='h-full shrink-0 items-center justify-center overflow-y-auto md:flex md:w-full lg:w-[35%]'>
          <Outlet />
        </section>
        <div className='h-full w-full overflow-hidden'>
          <img className='h-full w-full flex-1 scale-105 object-cover object-center' src='/gardu-induk.webp' alt='Gardu Induk' />
        </div>
      </main>
    </Suspense>
  )
}

const Fallback = () => {
  return (
    <main className='h-dvh items-center overflow-hidden lg:flex'>
      <section className='flex h-full w-full shrink-0 items-center justify-center overflow-y-auto lg:w-[35%]'>
        <Loader2 className='h-12 w-12 animate-spin text-primary' />
      </section>
      <div className='h-full w-full animate-pulse overflow-hidden bg-muted-foreground/50' />
    </main>
  )
}
