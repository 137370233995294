import lazyImport from '@/hooks/lazy-import'

export const User404Page = lazyImport(() => import('./404').then(({ User404Page }) => ({ default: User404Page })))
export const UserHomePage = lazyImport(() => import('./home').then(({ UserHomePage }) => ({ default: UserHomePage })))
export const UserBuatLaporanPage = lazyImport(() => import('./buat-laporan').then(({ UserBuatLaporanPage }) => ({ default: UserBuatLaporanPage })))
export const UserStatusLaporanPage = lazyImport(() => import('./status-laporan').then(({ UserStatusLaporanPage }) => ({ default: UserStatusLaporanPage })))
export const UserStatusLaporanDetailPage = lazyImport(() => import('./status-laporan/detail').then(({ UserStatusLaporanDetailPage }) => ({ default: UserStatusLaporanDetailPage })))

import { toast } from 'sonner'
import { Suspense } from 'react'
import { useMutation } from '@tanstack/react-query'
import { Loader2, LogOut, User } from 'lucide-react'
import { Outlet, NavLink, useNavigate } from 'react-router-dom'

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'

import { API } from '@/lib'
import { accountStore } from '@/stores'

export const UserLayout = () => {
  const { account, setAccount } = accountStore()

  const navigate = useNavigate()

  const { mutate } = useMutation({
    mutationKey: ['atuh/logout'],
    mutationFn: async () => {
      API.resetToken()
      setAccount(null)
      navigate('/')
    },
    onSuccess: async () => {
      return await API.post('/v1/auth/logout')
    },
    onSettled: () => {
      toast.success('Logout success', { description: 'You have been logged out' })
    }
  })

  return (
    <Suspense fallback={<UserFallback withHeader={true} />}>
      <main>
        <div className='border-b shadow-sm'>
          <nav className='mx-auto flex h-16 max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8'>
            <div className='flex h-full items-center gap-6'>
              <img src='/PLN.png' className='w-8' />
              <ul className='flex h-full items-center gap-3 text-sm md:gap-6'>
                {navigation.map(item => (
                  <li className='h-full' key={item.name}>
                    <NavLink
                      // need to fix active state on /user
                      to={item.href}
                      className='flex h-full items-center justify-center border-b-2 border-b-transparent font-medium text-muted-foreground transition-all [&.active]:border-b-app-blue [&.active]:text-foreground'
                    >
                      {item.name}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Avatar>
                  <AvatarImage src={account!.image} />
                  <AvatarFallback>{account!.name[0]}</AvatarFallback>
                </Avatar>
              </DropdownMenuTrigger>
              <DropdownMenuContent align='end' className='w-40'>
                <DropdownMenuLabel>Wiscaksono</DropdownMenuLabel>
                <DropdownMenuLabel className='text-xs font-normal text-muted-foreground'>{account?.up2b.name}</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem onClick={() => navigate('/profile')}>
                  <User className='mr-2 h-4 w-4' />
                  <span>Profile</span>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => mutate()}>
                  <LogOut className='mr-2 h-4 w-4' />
                  <span>Log out</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </nav>
        </div>
        <section className='mx-auto max-w-7xl'>
          <Outlet />
        </section>
      </main>
    </Suspense>
  )
}

export const UserFallback = ({ withHeader = false }: { withHeader?: boolean }) => {
  return (
    <main>
      {withHeader && (
        <div className='border-b shadow-sm'>
          <nav className='mx-auto flex h-16 max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8'>
            <div className='flex h-full items-center gap-6'>
              <img src='/PLN.png' className='w-8' />
              <ul className='flex h-full items-center gap-6 text-sm'>
                {navigation.map(item => (
                  <li className='h-full' key={item.name}>
                    <p className='flex h-full items-center justify-center border-b-2 border-b-transparent font-medium text-muted-foreground transition-all [&.active]:border-b-app-blue [&.active]:text-foreground'>
                      {item.name}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
            <div className='h-10 w-10 animate-pulse rounded-full bg-muted-foreground' />
          </nav>
        </div>
      )}
      <section className='mx-auto flex h-[calc(100dvh-65px)] max-w-7xl items-center justify-center'>
        <Loader2 className='h-12 w-12 animate-spin text-primary' />
      </section>
    </main>
  )
}

const navigation = [
  { name: 'Home', href: '/' },
  { name: 'Buat Laporan', href: '/buat-laporan' },
  { name: 'Status Laporan', href: '/status-laporan' }
]
