import { memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import { useQueryClient } from '@tanstack/react-query'
import { DatabaseZap, Moon, Sun, ListCollapse, Search } from 'lucide-react'
import { useState, useEffect, Fragment, useCallback } from 'react'

import { Button } from '@/components/ui/button'
import { CommandDialog, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, CommandSeparator } from '@/components/ui/command'

import { layoutStore } from '@/stores'
import { useTheme } from './theme-provider'
import { cn, adminMenu, Menu } from '@/lib'

export const CommandMenu = memo(() => {
  const { setTheme } = useTheme()

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [open, setOpen] = useState(false)
  const [isMac, setIsMac] = useState(false)
  const [setLayoutSize, isCollapsed, setIsCollapsed] = layoutStore(useShallow(state => [state.setLayoutSizes, state.isCollapsed, state.setIsCollapsed]))

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        setOpen(open => !open)
      }
    }

    document.addEventListener('keydown', down)
    return () => document.removeEventListener('keydown', down)
  }, [])

  useEffect(() => {
    const platform = navigator.userAgent.toLowerCase().includes('mac')
    setIsMac(platform)
  }, [])

  const handleClear = useCallback(() => {
    if ('caches' in window) {
      caches.keys().then(names => {
        names.forEach(name => {
          caches.delete(name)
        })
      })
    }

    if ('localStorage' in window) {
      localStorage.clear()
    }

    if ('sessionStorage' in window) {
      sessionStorage.clear()
    }

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(registrations => {
        registrations.forEach(registration => {
          registration.unregister()
        })
      })
    }

    queryClient.clear()

    window.location.reload()
  }, [])

  const handleCollapse = useCallback(() => {
    setIsCollapsed(isCollapsed ? false : true)
    setLayoutSize(isCollapsed ? [15, 85] : [0, 100])
    setOpen(false)
    navigate(0)
  }, [])

  return (
    <>
      <Button variant='outline' onClick={() => setOpen(true)} className='items-center gap-2 px-2 text-muted-foreground hidden md:flex'>
        <Search size={20} />
        <span className='mr-4'>Cari Menu</span>
        <kbd className='bg-muted px-1.5 rounded'>{isMac ? '⌘' : 'Ctrl'} + k</kbd>
      </Button>
      <CommandDialog open={open} onOpenChange={setOpen}>
        <CommandInput placeholder='Search menu' />
        <CommandList>
          <CommandEmpty>No results found.</CommandEmpty>
          {adminMenu.map(item =>
            item.subMenu?.length ? (
              <CommandGroup heading={item.name} key={item.name}>
                {item.subMenu.map(child => (
                  <CommandMenuItem setOpen={setOpen} data={child} key={child.name} />
                ))}
              </CommandGroup>
            ) : (
              <CommandMenuItem setOpen={setOpen} data={item} key={item.name} />
            )
          )}
          <CommandGroup heading='User Interface'>
            <CommandItem
              className='gap-x-2'
              onSelect={() => {
                setTheme('dark')
                setOpen(false)
              }}
            >
              <Moon />
              <span>Gelap</span>
            </CommandItem>
            <CommandSeparator />
            <CommandItem
              className='gap-x-2'
              onSelect={() => {
                setTheme('light')
                setOpen(false)
              }}
            >
              <Sun />
              <span>Terang</span>
            </CommandItem>
          </CommandGroup>
          <CommandSeparator />
          <CommandItem className='gap-x-2' onSelect={handleClear}>
            <DatabaseZap />
            <span>Bersihkan cache</span>
          </CommandItem>
          <CommandSeparator />
          <CommandItem className='gap-x-2' onSelect={handleCollapse}>
            <ListCollapse className={cn(isCollapsed ? '' : 'rotate-180')} />
            <span>{isCollapsed ? 'Buka' : 'Tutup'} sidebar</span>
          </CommandItem>
        </CommandList>
      </CommandDialog>
    </>
  )
})

interface MenuProps {
  data: Menu | Omit<Omit<Menu, 'subMenu'>, 'role'>
  setOpen: (open: boolean) => void
}

const CommandMenuItem = ({ data, setOpen }: MenuProps) => {
  const navigate = useNavigate()

  return (
    <Fragment>
      <CommandItem
        className='gap-x-2'
        onSelect={() => {
          setOpen(false)
          navigate(`${data.href}`)
        }}
      >
        <data.icon />
        <span>{data.name}</span>
      </CommandItem>
      <CommandSeparator />
    </Fragment>
  )
}
