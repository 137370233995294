import lazyImport from '@/hooks/lazy-import'

export const Admin404Page = lazyImport(() => import('./404').then(({ Admin404Page }) => ({ default: Admin404Page })))
export const AdminHomePage = lazyImport(() => import('./home').then(({ AdminHomePage }) => ({ default: AdminHomePage })))
export const AdminCalendarPage = lazyImport(() => import('./calendar').then(({ AdminCalendarPage }) => ({ default: AdminCalendarPage })))

export const AdminBoardPage = lazyImport(() => import('./board').then(({ AdminBoardPage }) => ({ default: AdminBoardPage })))
export const AdminBoardDetailPage = lazyImport(() => import('./board').then(({ AdminBoardDetailPage }) => ({ default: AdminBoardDetailPage })))
export const AdminBoardDetailCreateReport = lazyImport(() => import('./board').then(({ AdminBoardDetailCreateReport }) => ({ default: AdminBoardDetailCreateReport })))

export const AdminLaporanPage = lazyImport(() => import('./laporan').then(({ AdminLaporanPage }) => ({ default: AdminLaporanPage })))
export const AdminLaporanDetailPage = lazyImport(() => import('./laporan').then(({ AdminLaporanDetailPage }) => ({ default: AdminLaporanDetailPage })))

export const AdminLaporanArchivedPage = lazyImport(() => import('./laporan-archived').then(({ AdminLaporanArchivedPage }) => ({ default: AdminLaporanArchivedPage })))
export const AdminLaporanArchivedDetailPage = lazyImport(() => import('./laporan-archived').then(({ AdminLaporanArchivedDetailPage }) => ({ default: AdminLaporanArchivedDetailPage })))

export const AdminDMUnit = lazyImport(() => import('./data-master').then(({ AdminDMUnit }) => ({ default: AdminDMUnit })))
export const AdminDMGardu = lazyImport(() => import('./data-master').then(({ AdminDMGardu }) => ({ default: AdminDMGardu })))
export const AdminDMBay = lazyImport(() => import('./data-master').then(({ AdminDMBay }) => ({ default: AdminDMBay })))
export const AdminDMJenisGangguan = lazyImport(() => import('./data-master').then(({ AdminDMJenisGangguan }) => ({ default: AdminDMJenisGangguan })))
export const AdminDMSubJenisGangguan = lazyImport(() => import('./data-master').then(({ AdminDMSubJenisGangguan }) => ({ default: AdminDMSubJenisGangguan })))

export const AdminAMDetail = lazyImport(() => import('./account-management').then(({ AdminAMDetail }) => ({ default: AdminAMDetail })))
export const AdminAMList = lazyImport(() => import('./account-management').then(({ AdminAMList }) => ({ default: AdminAMList })))
export const AdminSettings = lazyImport(() => import('./settings').then(({ AdminSettings }) => ({ default: AdminSettings })))

export const AdminMTTRGardu = lazyImport(() => import('./mttr').then(({ AdminMTTRGardu }) => ({ default: AdminMTTRGardu })))
export const AdminMTTRBay = lazyImport(() => import('./mttr').then(({ AdminMTTRBay }) => ({ default: AdminMTTRBay })))

export const AdminTrashList = lazyImport(() => import('./trash').then(({ AdminTrashList }) => ({ default: AdminTrashList })))
export const AdminProfileDetail = lazyImport(() => import('./profile').then(({ AdminProfileDetail }) => ({ default: AdminProfileDetail })))

import { Suspense } from 'react'
import { Loader2 } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'
import { Outlet, useNavigate } from 'react-router-dom'

import { Header, Sidebar } from './_components'
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '@/components/ui/resizable'

import { cn } from '@/lib'
import { accountStore, layoutStore } from '@/stores'

export const AdminLayout = () => {
  const navigate = useNavigate()
  const account = accountStore(useShallow(state => state.account))
  const isAuthenticated = Boolean(account)

  const [setIsCollapsed, sizes, setSizes] = layoutStore(useShallow(state => [state.setIsCollapsed, state.layoutSizes, state.setLayoutSizes]))

  if (!isAuthenticated) navigate('/')

  return (
    <ResizablePanelGroup
      className='bg-muted dark:bg-background'
      direction='horizontal'
      onLayout={state => {
        setSizes(state)
        if (state[0] < 4) return setIsCollapsed(true)
        else setIsCollapsed(false)
      }}
    >
      <ResizablePanel
        className={cn('bg-background hidden lg:flex flex-col min-w-[50px] h-dvh')}
        style={{ padding: calculatePadding(sizes[0]) }}
        maxSize={17}
        defaultSize={sizes[0] ?? 17}
        collapsible
      >
        <Sidebar />
      </ResizablePanel>
      <ResizableHandle withHandle className='lg:flex hidden' />
      <ResizablePanel className='transition-all' defaultSize={sizes[1] ?? 83} minSize={83}>
        <div className='h-dvh overflow-y-auto -mr-2 lg:mr-0'>
          <Header />
          <Suspense fallback={<Fallback withMargin={false} />}>
            <Outlet />
          </Suspense>
        </div>
      </ResizablePanel>
    </ResizablePanelGroup>
  )
}

interface FallbackProps {
  withMargin?: boolean
}

export const Fallback = ({ withMargin = true }: FallbackProps) => {
  return (
    <main className='flex h-[calc(100dvh-65px)] w-full items-center overflow-hidden'>
      <div className={`ml-0 flex w-full items-center justify-center ${withMargin ? 'lg:ml-72' : ''}`}>
        <Loader2 className='h-12 w-12 animate-spin text-primary' />
      </div>
    </main>
  )
}

export function calculatePadding(value: number): number {
  // Given points
  const value1 = 20
  const padding1 = 24

  const value2 = 18
  // Let's assume a second known padding value for demonstration
  // For example, if value = 18, padding = 20 (this should be based on actual data or requirement)
  const padding2 = 20

  // Calculate the slope (a) of the linear function
  const a = (padding2 - padding1) / (value2 - value1)

  // Calculate the intercept (b) of the linear function
  const b = padding1 - a * value1

  const final = a * value + b

  // Calculate and return the padding for the given value
  return final < 5 ? 5 : final
}
