import { lazy as _lazy, ComponentType } from 'react'

import { Button } from '@/components/ui/button'

export default (function lazy(factory) {
  return _lazy(() =>
    factory().catch(a => {
      console.error(a)
      return importErrorHandler()
    })
  )
} as typeof _lazy)

let hasError = false

export function importErrorHandler(): { default: ComponentType<unknown> } {
  const time = Number(window.location.hash.match(/#s(\d+)/)?.[1])
  const now = Date.now()
  const isReloading = !time || time + 10_000 < now
  if (isReloading) {
    window.location.hash = `#s${now}`
    window.location.reload()
  }

  const module = {
    default: () => {
      if (hasError) {
        return null
      }
      hasError = true
      return (
        <div className='fixed inset-0 flex items-center justify-center bg-black/80 bg-opacity-50'>
          <div className='rounded-lg bg-background p-6 text-center shadow-lg'>
            <p className='mb-4'>
              Oops, something went wrong. <Button onClick={() => window.location.reload()}>Reload</Button>
            </p>
          </div>
        </div>
      )
    }
  }

  return module
}
