import { useState } from 'react'
import { Menu, Zap } from 'lucide-react'
import { NavLink } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'

import { Button } from '@/components/ui/button'
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'

import { cn } from '@/lib'
import { accountStore, layoutStore } from '@/stores'
import { adminMenu, Menu as TMenu } from '@/lib/constants'

export const SidebarMobile = () => {
  const [open, onOpenChange] = useState(false)
  const [account] = accountStore(useShallow(state => [state.account]))
  const [accordionOpen, setAccordionOpen] = layoutStore(useShallow(state => [state.accordionOpen, state.setAccordionOpen]))

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetTrigger asChild>
        <Button size='icon' variant='ghost' className='block lg:hidden'>
          <Menu size={24} className='mx-auto' />
        </Button>
      </SheetTrigger>
      <SheetContent side='left' className='block lg:hidden overflow-y-auto scrollbar-hide'>
        <header className={cn('mb-5 flex h-16 shrink-0 items-center gap-x-2.5 transition-all')}>
          <img src='/PLN.png' className='w-10 dark:hidden shrink-0' alt='PLN Logo' />
          <Zap size={52} className='hidden dark:block shrink-0' fill='white' />
          <div className='truncate'>
            <p className='text-xl font-semibold truncate'>Lapormas</p>
            <p className='font-medium truncate text-muted-foreground'>{account!.up2b.name}</p>
          </div>
        </header>
        <ul>
          <Accordion type='multiple' value={accordionOpen} onValueChange={e => setAccordionOpen(e)}>
            {adminMenu
              .filter(item => item.role.includes(account!.role))
              .map((menu, i) =>
                menu.subMenu ? (
                  <li key={i}>
                    <AccordionItem value={menu.name}>
                      <AccordionTrigger className='rounded p-2 text-sm font-semibold leading-6 text-muted-foreground transition-colors [&.active]:bg-muted [&.active]:text-app-blue'>
                        <div className='flex items-center gap-x-3'>
                          <menu.icon size={24} />
                          <span className='text-sm'>{menu.name}</span>
                        </div>
                      </AccordionTrigger>
                      <AccordionContent className='relative ml-5'>
                        <div className='absolute left-0 top-0 h-full w-px bg-accent' />
                        <ul className='pl-2'>
                          {menu.subMenu.map((subMenu, j) => (
                            <MenuBtn key={j} data={subMenu} onOpenChange={onOpenChange} />
                          ))}
                        </ul>
                      </AccordionContent>
                    </AccordionItem>
                  </li>
                ) : (
                  <li key={i}>
                    <MenuBtn data={menu} onOpenChange={onOpenChange} />
                  </li>
                )
              )}
          </Accordion>
        </ul>
      </SheetContent>
    </Sheet>
  )
}

interface MenuProps {
  data: TMenu | Omit<Omit<TMenu, 'subMenu'>, 'role'>
  onOpenChange: (open: boolean) => void
}

const MenuBtn = ({ data, onOpenChange }: MenuProps) => {
  return (
    <NavLink
      to={data.href!}
      onClick={() => onOpenChange(false)}
      className='flex items-center gap-x-3 rounded p-2 text-sm font-semibold leading-6 text-muted-foreground transition-colors [&.active]:bg-muted [&.active]:text-app-dark-blue dark:[&.active]:text-primary'
    >
      <data.icon size={24} />
      <span className='text-sm'>{data.name}</span>
    </NavLink>
  )
}
