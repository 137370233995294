import { toast } from 'sonner'
import { twMerge } from 'tailwind-merge'
import { type ClassValue, clsx } from 'clsx'

import type { FieldErrors } from 'react-hook-form'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

/**
 * Exclude keys from object
 * @param obj
 * @param keys
 */
export const exclude = <Type, Key extends keyof Type>(obj: Type, keys: Key[]): Omit<Type, Key> => {
  for (const key of keys) {
    delete obj[key]
  }
  return obj
}

/**
 * Wait for a number of milliseconds.
 * @param ms - Number of milliseconds to wait.
 * @returns Promise that resolves after the given number of milliseconds.
 */

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function toastErrorMessage<T extends FieldErrors>(errors: FieldErrors<T>) {
  if (Object.keys(errors).length === 0) return
  console.warn(errors)

  function extractMessages(errorObject: unknown): string[] {
    const messages = new Set<string>()

    function walk(obj: unknown) {
      if (!obj) return
      if (Array.isArray(obj)) {
        obj.forEach(item => walk(item))
      } else if (typeof obj === 'object' && obj !== null) {
        const objWithMessage = obj as { message?: string; [key: string]: unknown }
        if (objWithMessage.message && typeof objWithMessage.message === 'string') {
          messages.add(objWithMessage.message)
        }
        Object.values(objWithMessage).forEach(value => {
          walk(value)
        })
      }
    }

    walk(errorObject)
    return Array.from(messages)
  }

  const messages = extractMessages(errors)
  toast.warning('Form tidak valid', {
    description: messages.join(',\n')
  })
}

export class TypedURLSearchParams<T extends string> {
  private params: URLSearchParams

  constructor(init?: string | Record<string, string> | string[][] | URLSearchParams) {
    this.params = new URLSearchParams(init)
  }

  get size(): number {
    return this.params.size
  }

  append(name: T, value: string): void {
    this.params.append(name, value)
  }

  delete(name: T, value?: string): void {
    if (value === undefined) {
      this.params.delete(name)
    } else {
      const values = this.params.getAll(name).filter(v => v !== value)
      this.params.delete(name)
      values.forEach(v => this.params.append(name, v))
    }
  }

  get(name: T): string | null {
    return this.params.get(name)
  }

  getAll(name: T): string[] {
    return this.params.getAll(name)
  }

  has(name: T, value?: string): boolean {
    if (value === undefined) {
      return this.params.has(name)
    } else {
      return this.params.getAll(name).includes(value)
    }
  }

  set(name: T, value: string): void {
    this.params.set(name, value)
  }

  sort(): void {
    this.params.sort()
  }

  toString(): string {
    return this.params.toString()
  }

  forEach(callbackfn: (value: string, key: string, parent: TypedURLSearchParams<T>) => void, thisArg?: unknown): void {
    this.params.forEach((value, key) => {
      callbackfn.call(thisArg, value, key as T, this)
    })
  }

  entries(): IterableIterator<[T, string]> {
    return this.params.entries() as IterableIterator<[T, string]>
  }

  keys(): IterableIterator<T> {
    return this.params.keys() as IterableIterator<T>
  }

  values(): IterableIterator<string> {
    return this.params.values()
  }

  // Method to access the underlying URLSearchParams
  getURLSearchParams(): URLSearchParams {
    return this.params
  }
}

/**
 * Converts a data URI to a Blob object.
 *
 * @param dataURI - The data URI to convert.
 * @returns A Blob object representing the data from the URI.
 *
 * @remarks
 * This function supports both base64 and URI-encoded data URIs.
 * It extracts the MIME type and data from the URI, then creates a Blob with the correct type.
 *
 * @example
 * const dataURI = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACklEQVR4nGMAAQAABQABDQottAAAAABJRU5ErkJggg==";
 * const blob = dataURIToBlob(dataURI);
 * console.log(blob.type); // Output: "image/png"
 */
export function dataURIToBlob(dataURI: string): Blob {
  const splitDataURI = dataURI.split(',')
  const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i)
  return new Blob([ia], { type: mimeString })
}

/**
 * Convert a string to title case.
 * @param str - The string to convert to title case.
 * @returns The string converted to title case.
 */
export function toTitleCase(str: string) {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}
